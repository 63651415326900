import { FormControl, FormControlLabel, FormLabel, IconButton, Radio, RadioGroup, Toolbar, styled } from '@mui/material';
import React from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MuiDrawer from '@mui/material/Drawer';
import { drawerWidth } from '../../../../config/constant';
import { SideBarWrap } from './style';
import SelectBox from '../../../Reuseable/SelectBox';
import CalenderSelect from '../../../Reuseable/CalenderSelect';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { useDispatch, useSelector } from 'react-redux';
import { setDateRange, setReportFilter, setResult } from '../../../../redux/Global/actionCreator';
import moment from 'moment';

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            height: 'calc(100vh - 64px)',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(6),
                },
            }),
        },
    }),
);

const SideBar = ({ open, toggleDrawer }) => {

    const dispatch = useDispatch()
    const { startDate, endDate } = useSelector(state => state.Global.dateRange)
    const [startDateValue, setStartDateValue] = React.useState(startDate)
    const [endDateValue, setEndDateValue] = React.useState(endDate)
    const result = useSelector(state => state.Global.result)
    const { state: stateToBeFiltered, broker: brokerToBeFiltered } = useSelector(state => state.Global.tobeFiltered);
    const { state: stateReportFilter, broker: brokerReportFilter } = useSelector(state => state.Global.reportFilter)

    const stateOptions = stateToBeFiltered?.map((option, index) => {
        return {
            value: option,
            label: option,
            id: index + 1
        }
    })

    const brokerOptions = brokerToBeFiltered?.map((option) => {
        return {
            value: option.brokerId,
            label: option.brokerName,
            id: option.brokerId
        }
    })

    const handleStateFilter = (event) => {
        dispatch(setReportFilter({
            state: event,
            broker: brokerReportFilter
        }))
    }

    const handleBrokerFilter = (event) => {
        dispatch(setReportFilter({
            state: stateReportFilter,
            broker: event
        }))
    }

    const handleStartDate = (date) => {
        setStartDateValue(date)
        dispatch(setDateRange({
            startDate: moment(date).format("YYYY-MM-DD") === "Invalid date" ? null : moment(date).format("YYYY-MM-DD"),
            endDate: moment(endDateValue).format("YYYY-MM-DD") === "Invalid date" ? null : moment(endDateValue).format("YYYY-MM-DD")
        }))
    }

    const handleEndDate = (date) => {
        setEndDateValue(date)
        dispatch(setDateRange({
            startDate: moment(startDateValue).format("YYYY-MM-DD") === "Invalid date" ? null : moment(startDateValue).format("YYYY-MM-DD"),
            endDate: moment(date).format("YYYY-MM-DD") === "Invalid date" ? null : moment(date).format("YYYY-MM-DD")
        }))
    }

    const handleResult = (event) => {
        dispatch(setResult(event.target.value));
    };

    return (
        <SideBarWrap open={open}>
            <Drawer variant="permanent" open={open}>
                <Toolbar className='tool_bar'>

                    {open && <div className='filter'>
                        Filter
                    </div>}

                    <IconButton className='toggle_icon_button' onClick={toggleDrawer}>
                        {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </Toolbar>

                {open && <div className='filter_container'>

                    <FormControl>
                        <FormLabel id="won-failed-radio-buttons-group-label" className='radio_result'>Result</FormLabel>
                        <RadioGroup
                            className='result_radio_group'
                            aria-labelledby="won-failed-radio-buttons-group-label"
                            name="won-failed-radio-buttons-group"
                        >
                            <FormControlLabel value="won"
                                control={<Radio
                                    checked={result === 'won'}
                                    onChange={handleResult}
                                    icon={<RadioButtonCheckedIcon />} />}
                                label="Won" />
                            <FormControlLabel value="failed"
                                control={<Radio
                                    checked={result === 'failed'}
                                    onChange={handleResult}
                                    icon={<RadioButtonCheckedIcon />} />}
                                label="Failed" />
                        </RadioGroup>
                    </FormControl>

                    <div className='filter_item'>
                        <div className='filter_item_label'>State</div>
                        <SelectBox options={stateOptions} value={stateReportFilter} onChange={handleStateFilter} />
                    </div>

                    <div className='filter_item'>
                        <div className='filter_item_label'>Start Date</div>
                        <CalenderSelect name="start-date" onChange={handleStartDate} value={startDateValue} />
                    </div>

                    <div className='filter_item'>
                        <div className='filter_item_label'>End Date</div>
                        <CalenderSelect name="end-date" onChange={handleEndDate} value={endDateValue} />
                    </div>

                    <div className='filter_item'>
                        <div className='filter_item_label'>Broker</div>
                        <SelectBox options={brokerOptions} value={brokerReportFilter} onChange={handleBrokerFilter} />
                    </div>
                </div>}

            </Drawer>
        </SideBarWrap>
    )
}

export default SideBar