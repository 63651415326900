import styled from "styled-components";

const defaultFilterLabelFont = 'normal normal 600 16px Montserrat';
const black = '#000';

export const SideBarWrap = styled.div`

.tool_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}
.filter {
    font: normal normal bold 24px/29px Montserrat;
    color: var(--orange-color);
}
.toggle_icon_button {
    padding: 2px;
}
.MuiRadio-root {
    color: #CECECE !important;
}
.MuiSvgIcon-root {
    width: 20px;
    height: 20px;
}
.Mui-checked {
    color: var(--orange-color) !important;
}
.radio_result {
    font: ${defaultFilterLabelFont};
    color: ${black};
    &.Mui-focused {
        color: ${black};
    }
}
.result_radio_group {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: space-around;
    align-items: baseline;
}
.filter_container {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.filter_item_label {
    font-weight: 500;
    margin-bottom: 4px;
    font: ${defaultFilterLabelFont};
    color: ${black};
}
`