import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";


const AuthProtectedRoute = ({ component, path }) => {
  const isLoggedIn = useSelector(state => state.Auth.isLoggedIn);
  const user = useSelector(state => state.Auth.user);

  return (
    isLoggedIn ? <Outlet /> : <Navigate to={'/admin'} />
  );
};

export default AuthProtectedRoute;
