import { api } from "../../Services/api"

export const postLogoutUser = async () => {
    try {
        const response = await api.post('/admin-auth/logout', {}, {
            headers: {
                authorizationtoken: localStorage.getItem("token"),
            }
        })
        console.log("[postLogoutUser]  response -->", response);
    } catch (error) {
        console.log(error)
    }
}