import { ActionTypes } from "./actions";

const checkLogin =  localStorage.getItem('isLoggedIn');
const authToken = localStorage.getItem('token');
const user = localStorage.getItem('user');
  
const intialState = { 
    isLoggedIn : checkLogin,
    authToken : authToken,
    user: user
};

export const AuthReducer = (state = intialState, { type,payload }) => {
    switch (type) {
        case ActionTypes.LOGIN:
            return {
                ...state,
                isLoggedIn : payload.isLoggedIn,
                authToken : payload.authToken,
                user: payload.user
            };
        case ActionTypes.LOGOUT:
            return {
                ...state,
                isLoggedIn : payload.isLoggedIn,
                authToken : payload.authToken,
                user: payload.user
            };
        default : 
            return state;     
    }
}