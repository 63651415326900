import Styled from "styled-components";


const TableWrap = Styled.div`
.search-container {
  margin-bottom: 20px;
  margin-top: 20px;
  height: 40px;
  position: relative;
  text-align: right;
}
.table-view-card {
  box-shadow: unset !important;
}
.table_header_noWrap {
    white-space: nowrap;
}
.btn1 {
  background: transparent;
  border: none;
  font: normal 600 24px/29px Montserrat;
}
.common_table {
  padding: 0px 0px 0px 0px;
}
.common_header {
  padding: 0px;
  font: normal normal 600 30px/37px Montserrat;
  color: var(--orange-color);
}
.pagination {
    margin-top: 10px;
    padding-right: 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
    font: normal normal 600 20px Montserrat;
}
button.previous {
    background-color: transparent;
    border: none;
    font: normal normal 600 20px Montserrat;
    color: var(--orange-color);
    cursor: pointer;
}
.previous.disabled {
    color: #C2C2C2;
} 
button.next {
    background-color: transparent;
    border: none;
    font: normal normal 600 20px Montserrat;
    color: var(--orange-color);
    cursor: pointer;
}
.next.disabled {
    color: #C2C2C2;
}
.table-striped>tbody>tr:nth-of-type(odd)>* {
    color:${({ theme }) => theme["table_data-text"]};
    --bs-table-accent-bg: #FFEED8;
}
.table-bordered>:not(caption)>*>* {
    width:200px;
    border: 1px solid #CECECE;
}
.table>:not(:first-child) {
    border-top: 1px solid ${({ theme }) => theme["border"]};
    vertical-align: middle;
    text-align:center;
}
.table
{
    border-color: transparent;
}
table {
    border-collapse: collapse;
    thead {
        th {
            padding: 20px !important;
            color: #8D8D8D;
            font:normal normal medium 24px/29px Montserrat;
            text-align:center;
            vertical-align: middle;
        }
    }
    tbody {
        tr {
            td {
                line-height: 1.42857143;
                vertical-align: middle;
                padding: 10px !important;
                color:#131313;
                white-space: normal;
                width:300px;
            }
        }
    }
    .table>:not(:first-child) {
        border-top: 1px solid currentColor;
    }
    .imgfirstLetr {
        display: inline-block;
        background-color: #c3c3c3;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        color: #fff;
        box-shadow: inherit;
        font-size: 18px;
        
        padding: 6px 6px;
        margin: 0 auto;
        text-align: center;
        vertical-align: middle;
    }
    .imgFirstLetMain {
        span {
            display: inline-block;
            vertical-align: inherit;
        }
    }
    .btn-outline-light {
        color: #212529;
        border-color: #d3d3d3;
        background-color: yellow;
        &:hover {
            color: #212529;
            background-color: #f8f9fa;
            border-color: #999;
        }
    }
    .indicator {
        background-color:yellow;
        border-radius: 50%;
        display: block;
        height: 8px;
        width: 8px;
        padding: 1px;
        position: absolute;
        bottom: 5px;
        top: inherit;
        right: -3px;
        text-align: center;
        transition: top 0.1s ease-out;
        font-size: 0.675rem;
        color: #ffffff;
    }
    .actionLink {
        svg {
            height: 25px;
            path {
                fill: ${({ theme }) => theme["orange-color"]};
            }
        }
        padding: 5px;
    }
}
table.border-0 {
    tbody {
    tr {
      td {
        border: inherit;
        span {
            font-size: 14px;
        }
        }
    th {
        border: inherit;
        }
    }
    }
}
.table {
    &:not(:first-child) {
        border-top: inherit;
    }
}

@media (max-width: 991px) {
    table {
        tbody {
            tr {
                th {
                    white-space: nowrap;
                }
            }
        }
    }
}

`;

export default TableWrap;
