import React, { useState, useEffect, createContext } from "react";
import { useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Layout from "./components/Pages/Layout/main";
import { ThemeProvider } from 'styled-components';
import config from './config/config';
import { GlobalStyles } from './assets/styles/js/GlobalStyles';
import Login from './components/Pages/Login';
import AuthProtectedRoute from './Auth/authProtectedRoute';
import PageNotFound from "./components/Pages/PageNotFound";
import { useNetworkState } from 'react-use';
import NoInternet from "./components/Reuseable/ErrorPage/NoInternet";
import constant from './config/constant'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Prebidding from "./components/Pages/Prebidding";
import LeadQueuedForAuction from "./components/Pages/LeadQueuedForAuction";
import LiveAuction from "./components/Pages/LiveAuction";

const { theme, darktheme } = config;
export const isOnlineContext = createContext();
export const queryClient = new QueryClient()

const App = () => {
  const NetworkState = useNetworkState();
  const netState = NetworkState.online;
  const [online, Setonline] = useState(netState);
  const darkmode = useSelector((state) => state.Layout.darkmode);

  useEffect(() => {
    Setonline(netState)
  }, [netState])

  const isOnlineContextValue = { online };
  const UserInfo = [
    {
      user: true,
      tag: [constant.label_1, constant.label_2],
      api: "/broker-auth/login"
    },
    {
      user: false,
      tag: [constant.label_3],
      api: "/admin-auth/login"
    }
  ]

  return (
    <ThemeProvider theme={darkmode ? darktheme : theme} >
      <isOnlineContext.Provider value={isOnlineContextValue}>
        <GlobalStyles />
        <QueryClientProvider client={queryClient}>
          <Router basename={process.env.PUBLIC_URL}>
            <Routes>
              <Route path="" element={<NoInternet />}>
                <Route path="/" element={<Login props={UserInfo[1]} />} />
                <Route path="admin" element={<Login props={UserInfo[1]} />} />
                <Route element={<AuthProtectedRoute />}>
                  <Route path="home" element={<Layout />}>
                    <Route path="pre-bidding" element={<Prebidding />} />
                    <Route path="pre-auction" element={<LeadQueuedForAuction />} />
                    <Route path="auction" element={<LiveAuction />} />
                  </Route>
                </Route>
                <Route path="/noInternet" element={<NoInternet />}></Route>
                <Route path="*" element={<PageNotFound />}></Route>
              </Route>
            </Routes>
          </Router>
        </QueryClientProvider>
      </isOnlineContext.Provider>
    </ThemeProvider>
  );
}
export default App;
