import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { api } from '../../../../Services/api'
import { userLogout } from '../../../../redux/Auth/actionCreator'
import UIModal from '..'
import { SessionTimeoutWrap } from './style'
import { resetGlobal } from '../../../../redux/Global/actionCreator'

const SessionTimeout = ({ ...props }) => {
	const navigate = useNavigate()
	const authToken = useSelector((state) => state.Auth.authToken)
	const dispatch = useDispatch()

	const postLogoutUser = async () => {
		try {
			const response = await api.post(
				'/admin-auth/logout',
				{},
				{
					headers: {
						authorizationtoken: authToken,
					},
				}
			)
			dispatch(resetGlobal())
			navigate('/admin')
			console.log('[postLogoutUser] response-->', response)
			let result = {
				isLoggedIn: false,
				authToken: '',
				user: '',
			}
			dispatch(userLogout(result))
		} catch (error) {
			console.error('[postLogoutUser] error-->', error)
		}
	}

	const handleLoginButton = () => {
		postLogoutUser()
		props.setSessionTimeOut(false)
	}

	return (
		<SessionTimeoutWrap>
			<UIModal
				{...props}
				size="sm"
				centered
				header={<Modal.Title>Your Session Expired</Modal.Title>}
				closeButon={false}
				footer={
					<SessionTimeoutWrap>
						<Button
							className="login_button"
							onClick={handleLoginButton}
						>
							Login
						</Button>
					</SessionTimeoutWrap>
				}
			>
				<SessionTimeoutWrap>
					<p className="sessionTimeout_msg">
						You have to Login again to Continue
					</p>
				</SessionTimeoutWrap>
			</UIModal>
		</SessionTimeoutWrap>
	)
}

export default SessionTimeout
